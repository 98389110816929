/*** Loading fonts ***/
@font-face {
    font-family: 'JetBrains Mono';
    src: url('/public/assets/fonts/JetBrainsMono-Regular.ttf');
}

@font-face {
    font-family: 'Agressive';
    src: url('/public/assets/fonts/AGRESSIVE.otf');
}

@font-face {
    font-family: 'RussoOne';
    src: url('/public/assets/fonts/RussoOne-Regular.ttf');
}

/*** General styling ***/
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.15em;
    background-color: #151515;
    color: #cccccc;
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    color: #e1b655;
    font-family: RussoOne;
}

h2 {
    text-align: center;
}

.nerdfont {
    font-family: JetBrains Mono;
}


a:visited,
a {
    color: #8197bf;
}

b {
    color: #9aae6b;
}

/*** Main title styling ***/
.title-wrapper {
    font-family: RussoOne;
    margin-bottom: 100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.title-name {
    text-shadow: 0 0 150px #e1b655;
    margin: 0;
}
