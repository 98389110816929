/*** Projects styling ***/
.project {
    text-align: inherit;
    transition: all 0.2s ease-in-out;
    font-family: RussoOne;
    color: #8197bf;
}

.project:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.title-projects {
    text-align: right;
}

img {
    border-radius: 5px;
}

/*** Individual projects ***/
.nattklar,
.nonstdlib,
.neovim-plugins,
.cchat {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.kebab-lang,
.gremblebot,
.gochat {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.nattklar-body,
.nonstdlib-body,
.neovim-plugins-body,
.cchat-body {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.kebab-lang-body,
.gremblebot-body,
.gochat-body {
    display: flex;
    align-items: center;
}

.project-description {
    margin: 2em;
    padding: 2em;
    background-color: #282828;
    border-radius: 5px;
    color: #cccccc;
}

.kebab-lang img {
    width: 30em;
}

.nonstdlib img {
    width: 30em;
}

.nattklar img {
    width: 20em;
}

.gremblebot img {
    width: 30em;
}

.neovim-plugins img {
    width: 40em;
}
